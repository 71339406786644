import React, { Component } from 'react';
import HomePage from './Pages/HomePage'
import LoginPage from './Pages/LoginPage'
import SignupPage from './Pages/SignupPage'
import ForgotPasswordPage from './Pages/ForgotPasswordPage'
import {GetUser} from "./api/Auth";
import 'bulma/css/bulma.css'
import './App.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: "/"
        }
    }
  render() {
      let path = this.state.path;
      let Page = "";
      console.log("app",this.state.path);
      if(path==="/login") {
          Page = <LoginPage onNav={(path)=>{
              this.setState({path: path})
          }}/>;
      } else if(path==="/signup") {
          Page = <SignupPage onNav={(path)=>this.setState({path: path})}/>;
      }
      else if(path==="/forgot") {
          Page = <ForgotPasswordPage onNav={(path)=>this.setState({path: path})}/>;
      }
      else if(!GetUser()) {
          Page = <LoginPage onNav={(path)=>this.setState({path: path})}/>;
      } else {
          Page = <HomePage onNav={(path)=>this.setState({path: path})}/>;
      }
    return (
      <div>
          {Page}
      </div>
    );
  }
}

export default App;
