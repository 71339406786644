import React, {Component} from 'react';
import ActiveOrder from "../Components/ActiveOrders";
import {GetUser} from '../api/Auth'
import {ClearUser} from '../api/Auth'
import {ClearData} from '../api/Mapes'
import {ChangeCustomer} from '../api/Auth'
import {Customer} from '../api/Auth'
import {ToastContainer, toast} from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/mapes_panel_logo_hz.png';


class HomePage extends Component {
    constructor(props) {
        super(props);
        let user = GetUser();
        this.state = {
            user: user ? JSON.parse(JSON.stringify(user)) : false,
            customerNumber: GetUser() ? GetUser().custNumber : '',
            customer: Customer() ? Customer() : {data: {customer_name: '?'}}
        };
        this.textInput = React.createRef();
        this.signout = this.signout.bind(this);
        this.onUpdateCustomerNumber = this.onUpdateCustomerNumber.bind(this);
    }

    onUpdateCustomerNumber() {
        ChangeCustomer(this.textInput.current.value).then(result => {
            console.log(result);
            ClearData();
            let customer = Customer();
            this.setState({user: result, customer: customer});
        }).catch(err => {
            let toastId = toast.error("Customer Number Not Found", {
                position: toast.POSITION.TOP_LEFT
            });
            console.log(err);
        });
    }

    signout() {
        ClearUser();
        this.props.onNav("/login");
    }

    openMobileMenu() {
        var mnu = document.getElementById("header-content");
        mnu.classList.toggle("opened");
        var btn = document.getElementById("mobile-opener");
        window.setTimeout(btn.classList.toggle("clicked"), 200);
    }

    render() {
        if (!this.state.user) {
            this.props.onNav("/login");
            return null;
        }
        console.log("customer", this.state.customer);
        let Admin = null;
        if (this.state.user.isAdmin) {
            Admin = (
                <div className="field">
                    <label className="label">Customer #</label>
                    <input className="input is-small is-rounded" type="text" id="text"
                           defaultValue={this.state.customerNumber} ref={this.textInput}/>
                    <a className="button is-small is-centered is-rounded"
                       onClick={() => this.onUpdateCustomerNumber()}>
                        <span>Change #</span>
                        <span className="icon is-medium">
                            <i className="fas fa-exchange-alt"></i>
                        </span>
                    </a>
                </div>
            );
        }
        return (
            <div class="wrapper">
                <header className="site-header">
                    <div className="orange"></div>
                    <section id="header-content" className="header-content">
                        <div className="mobile-menu">
                            <div className="level is-mobile">
                                <a className="logo-mobile level-left">
                                    <img src={logo} alt="logo"/>
                                </a>
                                <a id="mobile-opener" className="mobile-opener level-right"
                                   onClick={() => this.openMobileMenu()}>
                                    <span class="line1"></span>
                                    <span class="line2"></span>
                                    <span class="line3"></span>
                                    <i class="fa fa-2x fa-times"></i>
                                </a>
                            </div>
                        </div>
                        <div className="desktop-menu">
                            <div className="">
                                <a href="#" className="logo-header"><img
                                    src="https://mapespanels.com/wp-content/themes/mapespanels/images/logo.png"
                                    alt="Mapes Panels, LLC"/>
                                </a>
                            </div>
                        </div>
                        <div className="user-actions">
                            <div>

                                <div style={{marginBottom: "1.5rem"}}>
                                    <small>Welcome</small>
                                    <h4 className="customer-name">{GetUser().name},</h4>
                                    <div className="company-name">{this.state.customer.data.customer_name}</div>
                                    <a className="button is-small is-centered is-rounded"
                                       onClick={() => this.signout()}>
                                        <span>Sign Out</span>
                                        <span class="icon is-medium">
                                            <i class="fas fa-sign-out-alt"></i>
                                        </span>
                                    </a>
                                </div>

                                {Admin}

                            </div>
                        </div>
                        <div class="eff-footer">
                            <span className="email">panels@mapes.com</span>
                        </div>
                    </section>
                </header>
                <div className="site-content container">
                    <ActiveOrder user={this.state.user}/>
                    <ToastContainer/>
                </div>
            </div>
        );
    }
}

export default HomePage;
