import React, { Component } from 'react';
import Input from '../Components/Input'
import {SendPasswordReset} from '../api/Auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/mapes_panel_logo_hz.png';

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
        console.log("id: " + this.state.id);
        this.onValueChange = this.onValueChange.bind(this);
        this.hasAccount = this.hasAccount.bind(this);
    }

    onValueChange(key, value) {
        console.log('onValueChange',key,value,this.state.custNumber);
        let data = {};
        data[key]=value;
        this.setState(data);
    }

    passwordReset() {
        SendPasswordReset(this.state.email).then(result=>{
            toast.success(`Password Reset Email Sent To ${this.state.email}`, {
                position: toast.POSITION.TOP_LEFT
            });
        }).catch(err=>{
            toast.error(err, {
                position: toast.POSITION.TOP_LEFT
            });
        });

    }

    hasAccount() {
        console.log("hasAccount");
        this.props.onNav("/login");
    }

    render() {
        return (
            <div className="">
                <div className="container" style={{marginTop: "15vh"}}>
                    <div className="columns is-centered">
                        <div className="column is-8-tablet is-6-desktop">
                            <div className="box">
                                <img src={logo} alt="logo" />
                                <Input name={'email'} label={'Email Address'}
                                   value={this.state.email}
                                   onChange={(value)=>this.onValueChange('email', value)}
                                />
                                <div style={{height: 40}}>
                                    <button className="button is-rounded is-success" onClick={()=>this.passwordReset()} style={{float: 'left'}}>Send Password Reset</button>
                                    <button className="button is-rounded"  onClick={()=>this.hasAccount()} style={{float: 'right'}}>Back to Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }
}
export default ForgotPasswordPage;
