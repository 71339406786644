import React, { Component } from 'react';
class Input extends Component {
    constructor(props) {
        super(props);
        console.log('Input',this.props);
        this.state = {
            label: this.props.label || '',
            placeholder: this.props.placeholder || '',
            value: this.props.value || '',
            type: this.props.type || 'text',
            name: this.props.name || 'name',
            autocomplete: this.props.autocomplete || 'on',
            horizontal: this.props.horizontal || false
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        console.log(event.target);
        this.setState({value: event.target.value});
        if(this.props.onChange) this.props.onChange(event.target.value);
    }
    render() {
        return (
            <div className="field">
                <label className="label">{this.state.label}</label>
                <div className="control">
                    <input className="input is-rounded" value={this.state.value} autoComplete={this.state.autocomplete} name={this.state.name} onChange={this.handleChange} type={this.state.type} placeholder={this.state.placeholder}/>
                </div>
            </div>
        )
    }
}
export default Input;
