let base_url = `https://mapes-v2.kodestarters.click/`;
//if(window.location.hostname!=='localhost') {
  //base_url=`https://mapes-v2.kodestarters.click/`
//}

module.exports = {
  get(url, params) {
    console.log(base_url,url,params);
    return new Promise((resolve, reject)=>{
      url = base_url+url;
      if(params) {
        Object.keys(params).forEach(key=>{
          console.log(key,params[key]);
          url = url.indexOf("?") < 0 ? url+="?" : url+="&";
          url+=`${key}=${params[key]}`;
        });
      }
      url = encodeURI(url);
      console.log("GET:",url);
      fetch(url, {
        method: "get",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then((response) => response.json())
          .then((responseJson) => {
              if(!responseJson.success) return resolve(false);
              return resolve(responseJson.data);
      })
    });
  },
  post(url, params) {
    console.log(base_url,url,params);
    return new Promise((resolve, reject)=>{
      fetch(base_url+url, {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify(params)
      })
      .then((response) => response.json())
      .then((responseJson) => {
          return resolve(responseJson.data);
      })
    });
  }
};
