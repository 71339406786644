import Http from "../api/http";

let ORDERS     = false;
let QUOTES     = false;
let CUSTOMER   = false;
let ORDERFILES = false;
let CUSTOMERNUMBER = 0;


export function GetCustomer(custNumber) {
    return new Promise(async (resolve, reject) => {
        ORDERS=false;
        QUOTES=false;
        ORDERFILES=false;
        let customer = await Http.get('portalUsers/getCustomer/'+custNumber);
        console.log('GetCustomer',customer);
        CUSTOMER = customer;
        return resolve(customer);
    });
}

export function GetOrders (custNumber) {
    return new Promise(async (resolve, reject) => {
        if(ORDERS && ORDERS.length>0 && ORDERS.custNumber===custNumber) return resolve(ORDERS);
        let orders = await Http.get('portalUsers/getOrders/'+custNumber);
        ORDERS = orders;
        return resolve(orders);
    });
}
export function GetQuotes (custNumber) {
    return new Promise(async (resolve, reject) => {
        if(QUOTES && QUOTES.length>0 && QUOTES[0].custNumber===parseInt(custNumber)) return resolve(QUOTES);
        let quotes = await Http.get('portalUsers/getQuotes/'+custNumber);
        QUOTES = quotes;
        return resolve(quotes);
    });
}

export function GetOrderFiles (custNumber) {
    return new Promise(async (resolve, reject) => {
        if(ORDERFILES) return resolve(ORDERFILES);
        let files = await Http.get('portalUsers/getFiles/'+custNumber);
        ORDERFILES = files;
        return resolve(files);
    });
}

export function GetLink(path) {
    return new Promise(async (resolve, reject) => {
        let link = await Http.post('portalUsers/getSharedLink',{path: path});
        return resolve(link);
    });
}

export function ClearData() {
    let ORDERS     = false;
    let QUOTES     = false;
    let CUSTOMER   = false;
    let ORDERFILES = false;
}


