import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Iframe from 'react-iframe'


const customStyles = {
    content : {
        position: 'fixed',
        top: '2%',
        left: '10%',
        right: 'auto',
        bottom: 'auto',
        height: '94%',
        width:  '80%',
        // transform:'translate(-50%, -50%)'
    },
};

const closeButton = {
    height: "33px",
    position: "absolute",
    right: "0px",
    marginTop: "-18px",
    marginRight: "0px",
    borderRadius: "50%",
    backgroundColor: "white",
    zIndex: 2
};


// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

class PdfModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            numPages: null,
            pageNumber: 1
        };
        console.log('PdfModal::constructor',this.props);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    componentDidUpdate(prevProps) {
        console.log('PdfModal::componentDidUpdate',this.props.isVisible, prevProps.isVisible);
        if(this.props.isVisible===prevProps.isVisible) return;
        this.setState({isVisible: this.props.isVisible, pdfUrl: this.props.pdfUrl});
        if (this.props.userID !== prevProps.userID) {
            this.fetchData(this.props.userID);
        }
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.onClose();
        this.setState({isVisible: false});
    }
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    onLoadError = (err) => {
        console.log(err);
    };

    render() {
        let Pdf = null;
        if(this.state.isVisible && this.state.pdfUrl) {
            console.log('pdfUrl',this.state.pdfUrl);
            Pdf = (
                <Iframe url={this.state.pdfUrl}
                        width="100%"
                        height="100%"
                        id="myId"
                        display="initial"
                        position="relative"
                        />
            )
        }
        return (
            <div>
                <Modal
                    isOpen={this.state.isVisible}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div style={closeButton} onClick={()=>this.closeModal()}>
                        <i className="fas fa-times-circle" style={{fontSize: "45px"}}></i>
                    </div>
                    <div style={{height: "100%", zIndex: 1}}>
                        {Pdf}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default PdfModal;

