import React, { Component } from 'react';
import Input from '../Components/Input'
import {Signup}  from '../api/Auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/mapes_panel_logo_hz.png';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            password2: '',
            custNumber: '',
            buttonState: 'enabled',
        };
        console.log("id: " + this.state.id);
        this.onValueChange = this.onValueChange.bind(this);
        this.validateForm  = this.validateForm.bind(this);
        this.hasAccount  = this.hasAccount.bind(this);
        this.signup  = this.signup.bind(this);
    }

    onValueChange(key, value) {
        console.log('onValueChange',key,value,this.state.custNumber);
        let data = {};
        data[key]=value;
        this.setState(data);
    }

    validateForm() {
        let a = ['custNumber', 'name', 'email', 'password', 'password2'];
        let state = this.state;
        console.log(this.state);

        for(let i = 0; i< a.length; i++) {
            let key = a[i];
            console.log('onValueChange',key,state[key],state.custNumber);
            if(!state[key] || state[key].length<2) {
                console.log(key,'failed validation',state[key]);
                return false;
            }
        }
        return true;
    }

    signup() {
        if(this.state.password !== this.state.password2) {
            toast.error("OOPS! Passwords Don't Match", {
                position: toast.POSITION.TOP_LEFT
            });
            return;
        }
        this.setState({buttonState: 'loading'});
        let toastId = toast.error('Creating Account', {
            position: toast.POSITION.TOP_LEFT
        });
        Signup(this.state.email, this.state.password,
        {
            custNumber: this.state.custNumber,
            name: this.state.name
        }).then(result=>{
            this.setState({buttonState: ''});
            toast.dismiss(toastId);
            this.props.onNav("/home");
        }).catch(err=>{
            toast.dismiss(toastId);
            this.setState({buttonState: 'enabled'});
            toast.error(err, {
                position: toast.POSITION.TOP_LEFT
            });
        });
    }
    hasAccount() {
        console.log("hasAccount");
        this.props.onNav("/login");
    }
    render() {
        let SubmitButton = <button className="button is-success is-rounded" onClick={()=>this.signup()} disabled={!this.validateForm()} >Signup</button>;
        if(this.state.buttonState === 'loading') {
            SubmitButton = <div className="button is-loading"><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i></div>;
        }

        return (
            <div>
                <div className="container" style={{marginTop: "15vh"}}>
                    <div className="columns is-centered">
                        <div className="column is-8-tablet is-6-desktop">
                            <div className="box">
                            <img src={logo} alt="logo" />
                                <Input placeholder={''}
                                       name={'customer_number'}
                                       label={'Customer Number'}
                                       value={this.state.custNumber}
                                       autocomplete={"off"}
                                       onChange={(value)=>this.onValueChange('custNumber', value)}
                                />
                                <Input placeholder={''} label={'Email Address'}
                                       name={'email'}
                                       value={this.state.email}
                                       onChange={(value)=>this.onValueChange('email', value)}
                                />
                                <Input placeholder={''}
                                       label={'Your Name'}
                                       name={'name'}
                                       value={this.state.name}
                                       onChange={(value)=>this.onValueChange('name', value)}
                                />
                                <Input placeholder={''} label={'Password'} type={'password'}
                                       name={'password'}
                                       value={this.state.password}
                                       onChange={(value)=>this.onValueChange('password', value)}
                                />
                                <Input placeholder={''} label={'Confirm Password'} type={'password'}
                                       name={'password2'}
                                       value={this.state.password2}
                                       onChange={(value)=>this.onValueChange('password2', value)}
                                />
                                <div className="field is-grouped">
                                    <div className="control">{SubmitButton}</div>
                                    <div className="control"><button className="button is-rounded"  onClick={()=>this.hasAccount()}>I have an account</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }
}
export default LoginPage;
