import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window.location.hostname!=='localhost' && window.location.protocol !== 'https:') {
    window.location = 'https:' + window.location.href.substring(window.location.protocol.length);
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
