// https://navisphere.chrobinson.com/ExternalTracking/ExternalShipmentList.aspx?SearchType=CustomerReference&SearchNumbers=P67412
import React, {Component} from 'react';
import {GetOrders} from "../api/Mapes";
import {GetOrderFiles} from "../api/Mapes";
import {GetQuotes} from "../api/Mapes";
import {GetLink} from "../api/Mapes";
import moment from 'moment';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PdfModal from '../Components/PdfModal'
import UserSettings from '../Components/UserSettings'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
export default class OrderTracking extends Component {
    constructor(props) {
        super(props);
        console.log("Construct");
    }
    render() {
        let url = `https://navisphere.chrobinson.com/ExternalTracking/ExternalShipmentList.aspx?SearchType=CustomerReference&SearchNumbers=${this.props.Order.orderNumber}`
        return (
            <div>
                <iframe src={url} style={{width: '800px',height:'400px'}}></iframe>
            </div>
        )
    }
}
