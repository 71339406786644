import React, {Component} from 'react';
import {GetOrders} from "../api/Mapes";
import {GetOrderFiles} from "../api/Mapes";
import {GetQuotes} from "../api/Mapes";
import {GetLink} from "../api/Mapes";
import moment from 'moment';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import PdfModal from '../Components/PdfModal'
import UserSettings from '../Components/UserSettings'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import OrderTracking from '../Components/OrderTracking'


class ActiveOrders extends Component {
    constructor(props) {
        super(props);
        console.log("Construct");
        this.state = {
            User: props.user,
            Loading: true,
            Orders: false,
            OrderFiles: false,
            filter: '',
            tabIndex: 0,
            pdfModalVisible: false,
            pdfUrl: false,
            links: {}
        };
        this.loadOrders = this.loadOrders.bind(this);
        this.getOrdersFiltered = this.getOrdersFiltered.bind(this);
        this.getQuotesFiltered = this.getQuotesFiltered.bind(this);
        this.downloadLink = this.downloadLink.bind(this);
        this.getLinks = this.getLinks.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.loadOrders();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.state.User) {
            console.log('componentDidUpdate', this.state.User.custNumber, this.props.user.custNumber);
        }
        if (!this.state.User || this.props.user.custNumber !== this.state.User.custNumber) {
            console.log('componentDidUpdate', this.props.user.custNumber);
            this.loadOrders(this.props.user.custNumber);
            this.setState({User: this.props.user});
        }
    }

    loadOrders(custNumber) {
        if (!custNumber) custNumber = this.state.User.custNumber;
        console.log("loadOrders", custNumber);
        let toastId = toast.info("Loading Data", {
            position: toast.POSITION.TOP_LEFT
        });

        GetOrders(custNumber).then(orders => {
            console.log("loadOrders", custNumber, 'orders', orders.length);
            GetOrderFiles(custNumber).then(files => {
                if (!files) files = {orders: [], quotes: []};
                console.log("loadOrders", custNumber, 'orderfiles', files.orders.length, 'quotefiles', files.quotes.length);
                GetQuotes(custNumber).then(quotes => {
                    if (!quotes) quotes = [];
                    console.log("loadOrders", custNumber, 'quotes', quotes.length);
                    toast.dismiss(toastId);
                    this.setState({Orders: orders, OrderFiles: files, Loading: false, Quotes: quotes});
                });
            });
        });
    }

    getOrdersFiltered(shipped) {
        if (!this.state.Orders) return this.state.Orders;
        let orders = [];
        if (shipped) {
            this.state.Orders.filter(a => a.shippedDate && a.shippedDate !== '0000-00-00 00:00:00').forEach(a => orders.push(a));
        } else {
            this.state.Orders.filter(a => a.shippedDate === '0000-00-00 00:00:00' || !(moment(a.shippedDate).isValid())).forEach(a => orders.push(a));
        }
        console.log('getOrdersFiltered', this.state.filter);
        // let orders = JSON.parse(JSON.stringify(this.state.Orders));
        let filter = this.state.filter ? this.state.filter.toLowerCase() : '';
        if (this.state.filter && this.state.filter.length > 0) {
            orders = orders.filter(a => (a.jobName && a.jobName.toLowerCase().indexOf(filter) > -1) || a.orderNumber.toLowerCase().indexOf(filter) > -1);
        }
        return orders.sort(this.compareOrders).reverse();
    }

    getQuotesFiltered() {
        if (!this.state.Quotes) return this.state.Quotes;
        console.log('getOrdersFiltered', this.state.filter);
        let quotes = JSON.parse(JSON.stringify(this.state.Quotes));
        let filter = this.state.filter ? this.state.filter.toLowerCase() : '';
        if (this.state.filter && this.state.filter.length > 0) {
            // quotes = quotes.filter(a => (a.data.job_name && a.data.job_name.toLowerCase().indexOf(filter) > -1));
            // let s  = `${a.data.job_name} ${a.quoteNumber}`.toLowerCase();
            // quotes = quotes.filter(a => (`${a.data.job_name} && a.data.job_name.toLowerCase().indexOf(filter) > -1));
            quotes = quotes.filter(a => {
                let s  = `${a.data.job_name} ${a.quoteNumber}`.toLowerCase();
                return s.indexOf(filter)>-1;
            });
        }
        return quotes.sort(this.compareQuotes).reverse();
    }

    compareOrders(a, b) {
        if (moment(a.orderDate).format('x') < moment(b.orderDate).format('x'))
            return -1;
        if (moment(a.orderDate).format('x') > moment(b.orderDate).format('x'))
            return 1;
        return 0;
    }

    compareQuotes(a, b) {
        if (moment(a.bidDate).format('x') < moment(b.bidDate).format('x'))
            return -1;
        if (moment(a.bidDate).format('x') > moment(b.bidDate).format('x'))
            return 1;
        return 0;
    }

    downloadLink(path, download) {
        let links = this.state.links;
        this.getLinks(path).then(result => {
            links[path] = result;
            if (path.indexOf(".pdf") > -1 && !download) {
                this.setState({
                    pdfModalVisible: true,
                    pdfUrl: result.view_url,
                    pdfDownloadUrl: result.download_url,
                    links
                });
            } else {
                this.downloadFile(result.download_url);
            }
        });
    }

    getLinks(path) {
        return new Promise(async (resolve, reject) => {
            let links = this.state.links;
            if (links[path]) {
                return resolve(links[path]);
            }
            let toastId = toast.info("Preparing File", {
                position: toast.POSITION.TOP_LEFT
            });
            GetLink(path).then(result => {
                toast.dismiss(toastId);
                return resolve(result);
            });
        })
    }

    downloadFile(url) {
        let iframe = this.refs.invisible;
        iframe.src = url;
    }

    closeModal() {
        this.setState({pdfModalVisible: false});
    }


    renderOrder(order, shipped) {
        console.log('PromiseDate', moment(order.promiseDate).utc().format(), moment(order.promiseDate).format("MMM Do Y"));
        let date = 'Estimated to ship ' + moment(order.promiseDate).utc().format("MMM Do Y");
        let active = order.shippedDate ? 'none' : 'display-inline';
        let fileList = this.state.OrderFiles.orders.filter(a => {
            // console.log(shipped, a.path_lower, )
            if(!shipped && a.path_lower.indexOf(`packinglist_`) > -1) return false;
            let order_number = order.orderNumber.toLowerCase();
            // console.log(order_number,a.path_lower);
            if (a.path_lower.indexOf(`/${order_number}/`) > -1) return true;
            if (a.path_lower.indexOf(`_${order_number}-`) > -1) return true;
            if (a.path_lower.indexOf(`${order_number}.`) > -1) return true;
            return false;
        });
        let Files = fileList.length === 0 ? (null) : [];
        let hasAcknowledgement = false;
        fileList.forEach(file => {
            let f_date = moment(file.date).format("MMM Do Y");
            if(!hasAcknowledgement) hasAcknowledgement = (file.path_lower.indexOf("acknowledgement")>-1);
            Files.push(
                <div className="control">
                    <a className="button is-small is-rounded" onClick={() => this.downloadLink(file.path_lower)}>
                        <span className="icon is-medium">
                            <i className="fa fa-arrow-down"></i>
                        </span>
                        <span>{file.name}</span>
                    </a>
                </div>
            );
        });
        if (Files == null || !hasAcknowledgement) {
            Files = [];
            let s = 'Acknowledgements may take 24-48 hours to generate';
            if (moment(order.orderDate).isBefore(moment('2019-02-19'))) s = 'Acknowledgement Unavailable';
            Files.push(
                <div className="">
                    <div className="">{s}</div>
                </div>
            )
        }
        if (moment(order.shippedDate).isAfter(moment('2019-01-01'))) {
            date = (
                <span>{`Shipped ${moment(order.shippedDate).utc().format("MMM Do Y")}`}</span>
            );
        }

        return (
            <div className="entry">
                <small className="date">{date}</small>
                <h5 className="job-title">
                    <b>Order #{order.orderNumber}, {order.jobName}</b>
                </h5>
                <div className="field is-grouped wrap">
                    {Files}
                </div>
            </div>
        )
    }

    renderQuote(quote) {
        // {"zhdel":"","zhquote_number":"157659","zhcust_number":"6151","zhshipnm":"S A M E","zhshipadr1":"","zhshipadr2":"","zhshipcity":"","zhshipst":"","zhshipzip":"0","zhjobname":"ENGELWOOD CLIFFS","zhattn":"ARIAN TURKU","zhquotedt":"2006-04-24","zhterms":"OP","zhquoteby":"JAY MARSHALL","zhsalesrep":"DESIGN EQ-RICK     06106661806","zhestfrt":"INCLUDED","zhfob":"RUTHERFORD, NJ","zhsqft":"3273","zhexpdays":"90","zhfaxno":"2019392201","zhphone":"2019392200","zhcompany":"ARCHITECTURAL WINDOW-MEA","zhnotot":"","zhbiddt":"2006-04-24","zhrepcode":"RS","zhredcomm":"","zhstdmsgc":"P","zhthick":"100","zhexter":"KYN","zhsubst1":"HBD","zhcore":"STY","zhsubst2":"HBD","zhinter":"KYN","quoteNumber":157659,"custNumber":6151,"bidDate":"2006-04-24T00:00:00-05:00","job_name":"ENGELWOOD CLIFFS"}
        let date = moment(quote.data.zhquotedt).format("MMM Do Y");
        let fileList = this.state.OrderFiles.quotes.filter(a => a.path_lower.toLowerCase().indexOf(quote.quoteNumber) > -1);
        let Files = fileList.length === 0 ? (null) : [];
        fileList.forEach(file => {
            let f_date = moment(file.date).format("MMM Do Y");
            Files.push(
                <div className="control">
                    <a className="button is-small is-rounded" onClick={() => this.downloadLink(file.path_lower)}>
                        <span className="icon is-medium">
                            <i className="fa fa-arrow-down"></i>
                        </span>
                        <span>{file.name}</span>
                    </a>
                </div>
            );
        });
        return (
            <div className="entry">
                <span className="date">{date}</span>
                <h5 className="job-title">
                    <b>Quote #{quote.quoteNumber}, {quote.data.job_name}</b>
                </h5>
                <div className="is-grouped">
                    {Files}
                </div>
            </div>
        )
    }

    render() {
        let orders = [];
        let quotes = [];
        let DisplayOrders = [];
        let DisplayQuotes = [];
        console.log("Start render", this.state.tabIndex);
        let pageCount = 0;
        let pageNumber = this.state.pageNumber;
        if (this.state.loading) {
            DisplayOrders = <div>Loading</div>;
            DisplayQuotes = <div>Loading</div>;
        } else if (this.state.tabIndex === 0 || this.state.tabIndex === 1) {
            orders = this.getOrdersFiltered(this.state.tabIndex === 1);
            pageCount = (Math.round(orders.length / 20));
            if (orders && orders.length > 0) {
                orders = orders.splice(20 * (pageNumber), 20);
                orders.forEach(order => {
                    DisplayOrders.push(this.renderOrder(order, this.state.tabIndex === 1));
                })
            }
        } else if (this.state.tabIndex === 2) {
            quotes = this.getQuotesFiltered();
            pageCount = (Math.round(quotes.length / 20));
            if (quotes && quotes.length > 0) {
                quotes = quotes.splice(20 * (pageNumber), 20);
                quotes.forEach(quote => {
                    DisplayQuotes.push(this.renderQuote(quote));
                })
            }
        }
        let Pagination = null;
        if (pageCount > 1) {
            Pagination =
                <nav className="pagination is-centered is-rounded" role="navigation" aria-label="pagination">
                    <ReactPaginate
                        previousLabel={'←'}
                        nextLabel={'→'}
                        breakLabel={'...'}
                        breakClassName={'pagination-ellipsis'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={(data) => this.setState({pageNumber: data.selected})}
                        pageClassName={'pagination-link'}
                        containerClassName={'pagination-list'}
                        subContainerClassName={'pagination-link'}
                        activeClassName={'is-current'}
                    />
                </nav>
        }

        let ShippedOrders = (
            <span>
            <div className="level">
                <h4 className="level-item-left level-title">
                    Shipped Orders
                </h4>
                <div className="level-item">
                    {Pagination}
                </div>
                <div className="level-item-right">
                    <div class="control has-icons-right">
                        <input className="input is-rounded" name={this.state.filter} value={this.state.filter}
                               onChange={(event) => this.setState({filter: event.target.value})}
                               placeholder="Job Name"/>
                        <span class="icon is-small is-right"><i class="fas fa-search"></i></span>
                    </div>
                </div>
            </div>
                {DisplayOrders}
            </span>
        );
        if (this.state.TrackOrder && this.state.tabIndex === 1) {
            ShippedOrders = (
                <div>
                    <h4 className="level-item-left level-title">
                        <span onClick={()=>this.setState({TrackOrder: false})} className="icon is-large" style={{color: '#dc5b2d'}}><i className="fa fa-arrow-left"></i></span>
                        Track Shipment {this.state.TrackOrder.orderNumber}
                    </h4>
                    <OrderTracking Order={this.state.TrackOrder}/>
                </div>
            );
        }
        console.log("End render");
        return (
            <div class="pad-mobile">

                <Tabs onSelect={index => this.setState({tabIndex: index, pageNumber: 0})}
                      selectedTabClassName="is-active">
                    <div className="tabs is-boxed">

                        <TabList>
                            <Tab><a><i className="fa fa-suitcase"></i>Open Orders</a></Tab>
                            <Tab><a><i className="fa fa-truck"></i>Shipped Orders</a></Tab>
                            <Tab><a><i className="fa fa-comments"></i>Quotes</a></Tab>
                            <Tab><a><i className="fa fa-cog"></i>Settings</a></Tab>
                        </TabList>

                    </div>

                    <TabPanel>
                        <div className="level">
                            <h4 className="level-item-left level-title">
                                Open Orders
                            </h4>
                            <div className="level-item">
                                {Pagination}
                            </div>
                            <div className="level-item-right">
                                <div class="control has-icons-right">
                                    <input className="input is-rounded" name={this.state.filter}
                                           value={this.state.filter}
                                           onChange={(event) => this.setState({filter: event.target.value})}
                                           placeholder="Job Search"/>
                                    <span class="icon is-small is-right">
                                            <i class="fas fa-search"></i>
                                        </span>
                                </div>
                            </div>
                        </div>
                        {DisplayOrders}
                    </TabPanel>
                    <TabPanel>
                        {ShippedOrders}
                    </TabPanel>
                    <TabPanel>
                        <div className="level">
                            <h4 className="level-item-left level-title">
                                Quotes
                            </h4>
                            <div className="level-item">
                                {Pagination}
                            </div>
                            <div className="level-item-right">
                                <div class="control has-icons-right">
                                    <input className="input is-rounded" name={this.state.filter}
                                           value={this.state.filter}
                                           onChange={(event) => this.setState({filter: event.target.value})}
                                           placeholder="Job Name"/>
                                    <span class="icon is-small is-right">
                                            <i class="fas fa-search"></i>
                                        </span>
                                </div>
                            </div>
                        </div>
                        {DisplayQuotes}
                    </TabPanel>
                    <TabPanel>
                        <UserSettings User={this.state.User}/>
                    </TabPanel>
                </Tabs>


                <PdfModal isVisible={this.state.pdfModalVisible} pdfUrl={this.state.pdfUrl}
                          onClose={() => this.closeModal()}/>
                <iframe ref="invisible" style={{display: "none"}}></iframe>
                <ToastContainer/>
            </div>


        );
    }
}

export default ActiveOrders;
