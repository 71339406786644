import fire from '../api/firebase';
import Http from "../api/http";
import {GetCustomer} from "./Mapes";

const USERTABLE = 'portalUsers';

export function GetUser() {
    console.log(localStorage.getItem("user"));
    if(localStorage.getItem("user") === null && localStorage.getItem("user") !== 'undefined') {
      return false;
    }
    console.log(localStorage.getItem("user"));
    return JSON.parse(localStorage.getItem("user"));
}


export function Customer() {
    console.log(localStorage.getItem("customer"));
    if(localStorage.getItem("customer") === null && localStorage.getItem("customer") !== 'undefined') {
        return false;
    }
    console.log(localStorage.getItem("customer"));
    return JSON.parse(localStorage.getItem("customer"));
}

export function ClearUser() {
    localStorage.clear();
}

export function ChangeCustomer(customerNumber) {
    return new Promise(async (resolve, reject) => {
        try {
            let customer = await GetCustomer(customerNumber);
            if(!customer) {
                return reject("Customer Number Not Found");
            }
            console.log('ChangeCustomer',customer);
            let user = JSON.parse(localStorage.getItem("user"));
            user.custNumber = customer.custNumber;
            localStorage.user = JSON.stringify(user);
            localStorage.customer = JSON.stringify(customer);
            return resolve(user);
        } catch(err) {
            return reject("Customer Number Not Found");
        }
    });
}

export function Login(email, password) {
  return new Promise((resolve, reject) => {
    fire.auth().signInWithEmailAndPassword(email, password).then(async (responsefirebase) => {
      console.log("Auth: Login", responsefirebase);
      localStorage.email = email;
      localStorage.token = responsefirebase.refreshToken;

      Http.get(USERTABLE, {firebaseid: responsefirebase.user.uid}).then(async (users) => {
        if(users.length==0) {
            return reject("Unable to find user");
        }
        users[0].isAdmin =(email.toLowerCase().indexOf("@mapes.com")>-1 || email.toLowerCase().indexOf("edmckee@mac.com")>-1);
        localStorage.user = JSON.stringify(users[0]);
        console.log(localStorage.user);
        await ChangeCustomer(users[0].custNumber);
        resolve(users[0]);
      });

    }).catch(error => {
      console.error("Auth: Login", error);
        reject(error.message);
    });
  });
}

export function UpdatePassword(oldPassword, newPassword) {
    let email = localStorage.getItem("email");
    console.log('UpdatePassword',email,oldPassword);
    return new Promise((resolve, reject) => {
        fire.auth().signInWithEmailAndPassword(email, oldPassword).then(async (responsefirebase) => {
            fire.auth().currentUser.updatePassword(newPassword).then(async (responsefirebase) => {
                resolve(true);
            }).catch(error=>{
                reject(error.message);
            })
        }).catch(error=>{
            console.log('login',error);
            reject(error.message);
        });
    });
}



export function Signup(email, password, payload) {
  console.log("Auth: Signup", email, password, payload);
  return new Promise(async (resolve, reject) => {
    let customer = await GetCustomer(payload.custNumber);
    if(!customer) {
      return reject("Customer Number Not Found");
    }
    fire.auth().createUserWithEmailAndPassword(email, password).then((response) => {
      console.log("Auth: Signup", "firebase", response.user);
      payload.firebaseid = response.user.uid;
      Http.post(USERTABLE+"/create", payload).then((response) => {
        console.log("Auth: Signup", "success", response);
        let user = response;
        user.isAdmin =(email.toLowerCase().indexOf("@mapes.com")>-1 || email.toLowerCase().indexOf("edmckee@mac.com")>-1);
        localStorage.user = JSON.stringify(user);
        resolve(response.user);
      }).catch(err => {
        console.error("Auth: Signup", err);
        reject(err);
      });
    }).catch(function (error) {
      // let errorCode = error.code;
      // let errorMessage = error.message;
      reject(error.message);
    });
  });
}

export function SendPasswordReset(email) {
  return new Promise((resolve, reject) => {
    fire.auth().sendPasswordResetEmail(email).then(function () {
      resolve(true);
    }).catch(function (error) {
      let errorCode = error.code;
      let errorMessage = error.message;
      if (errorCode === 'auth/invalid-email') {
        reject(errorMessage);
      } else if (errorCode === 'auth/user-not-found') {
        reject(errorMessage);
      }
      console.error(error);
    });
  });
}

export function IsUserLoggedIn() {
    var user = fire.auth().currentUser;
    console.log(user);
    return user !==null;
}
