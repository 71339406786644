import React, { Component } from 'react';
import Input from '../Components/Input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {UpdatePassword} from "../api/Auth";

class UserSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.User,
            password: {},
        };
        console.log("id: " + this.state.id);
        this.onValueChange = this.onValueChange.bind(this);
        this.onPasswordChange  = this.onPasswordChange.bind(this);
        this.updateUser  = this.updateUser.bind(this);
        this.updatePassword  = this.updatePassword.bind(this);
    }

    onValueChange(key, value) {
        let user = this.state.user;
        user[key]=value;
        this.setState({user: user});
    }

    onPasswordChange(key, value) {
        let password = this.state.password;
        password[key]=value;
        this.setState({password: password});

    }

    updateUser() {
        let toastId = toast.info("Updating User", {
            position: toast.POSITION.TOP_LEFT
        });
    }

    updatePassword() {
        let toastId = toast.info("Updating Password", {
            position: toast.POSITION.TOP_LEFT
        });
        UpdatePassword(this.state.password.current, this.state.password.newPassword).then(result=>{
            toast.dismiss(toastId);
            toast.success("Password Updated", {
                position: toast.POSITION.TOP_LEFT
            });
        }).catch(err=>{
            toast.dismiss(toastId);
            console.log(err);
            toast.success(err, {
                position: toast.POSITION.TOP_LEFT
            });
        });
    }

    render() {
        console.log('UserSetting',this.state.user);
        let user = this.state.user;
        return (
        <div className="columns is-variable is-8">
            <div className="column">
                <h4 className="subtitle">Update Name</h4>
                <Input name={'name'} label={'Your Name'}
                    value={user.name}
                    onChange={(value)=>this.onValueChange('name', value)}
                />
                <button className="button is-rounded is-orange" onClick={()=>this.updateUser()}>Update Name</button>
            </div>
            <div className="column">
                <h4 className="subtitle">Update Password</h4>
                <Input name={'password'} label={'Current Password'} type={'password'}
                    value={this.state.password.current}
                    onChange={(value)=>this.onPasswordChange('current', value)}
                />
                <Input name={'password'} label={'New Password'} type={'password'}
                    value={this.state.password.newPassword}
                    onChange={(value)=>this.onPasswordChange('newPassword', value)}
                />
                <button className="button is-rounded is-orange" onClick={()=>this.updatePassword()}>Change Password</button>
            </div>
            <ToastContainer />
        </div>
        )
    }
}

export default UserSettings;
