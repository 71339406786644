import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
const config = {
    apiKey: "AIzaSyCmejjMCeLo9t1o-fos-GH8X5YDjp8H530",
    authDomain: "mapes-portal.firebaseapp.com",
    databaseURL: "https://mapes-portal.firebaseio.com",
    projectId: "mapes-portal",
    storageBucket: "mapes-portal.appspot.com",
    messagingSenderId: "264507520053"
};
const fire=firebase.initializeApp(config);
export default fire;
