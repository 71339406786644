import React, { Component } from 'react';
import Input from '../Components/Input'
import {Login} from '../api/Auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../assets/images/mapes_panel_logo_hz.png';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
        console.log("id: " + this.state.id);
        this.onValueChange = this.onValueChange.bind(this);
        this.createAccount = this.createAccount.bind(this);
        this.login  = this.login.bind(this);
    }

    onValueChange(key, value) {
        console.log('onValueChange',key,value,this.state.custNumber);
        let data = {};
        data[key]=value;
        this.setState(data);
    }

    createAccount() {
        console.log("createAccount");
        this.props.onNav("/signup");
    }

    forgotPassword() {
        console.log("createAccount");
        this.props.onNav("/forgot");
    }

    login() {
        Login(this.state.email, this.state.password).then(result=>{
            this.props.onNav("/home");
        }).catch(err=>{
            toast.error(err, {
                position: toast.POSITION.TOP_LEFT
            });
            console.log(err);
        });
    }

    render() {
        return (
            <div className="">
                <div className="container" style={{marginTop: "15vh"}}>
                    <div className="columns is-centered">
                        <div className="column is-8-tablet is-6-desktop">
                            <div className="box">
                                <img src={logo} alt="logo" />
                                <Input name={'email'} label={'Email Address'}
                                   value={this.state.email}
                                   onChange={(value)=>this.onValueChange('email', value)}
                                />
                                <Input name={'password'} label={'Password'} type={'password'}
                                   value={this.state.password}
                                   onChange={(value)=>this.onValueChange('password', value)}
                                />
                                <div className="field is-grouped">
                                    <p class="control">
                                        <button className="button is-rounded is-success" onClick={()=>this.login()} >Login</button>
                                    </p>
                                    <p class="control">
                                        <button className="button is-rounded" onClick={()=>this.forgotPassword()}>Forgot Password
                                        </button>
                                    </p>
                                    <p class="control">
                                        <button className="button is-rounded" onClick={()=>this.createAccount()}>Create Account</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }
}
export default LoginPage;
